import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardSubtitle,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonButton,
  IonItem,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonText,
  IonInputPasswordToggle,
  IonProgressBar,
  AlertController,
} from '@ionic/angular/standalone';
import { Router, RouterModule } from '@angular/router';
import { states } from 'src/app/constants';
import { UserService } from 'src/app/services/user.service';
import { PasswordMatchValidator } from 'src/app/validators/password-match.validator';
import { WhiteSpaceCheckValidator } from 'src/app/validators/white-space-check.validator';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions, MaskitoElementPredicate } from '@maskito/core';
import { RegisterUser, RoleEnum } from 'src/app/models/register-user.model';
import { ToastService } from 'src/app/services/toast.service';
import { EduEmailValidator } from 'src/app/validators/edu-email.validator';
import { VerificationPage } from '../verification/verification.page';
import { UserVerificationService } from 'src/app/services/user-verification.service';
import { AuthService } from 'src/app/services/auth.service';
import { TokenService } from 'src/app/services/token.service';
import { CampusService } from 'src/app/services/campus.service';
import { Campus } from 'src/app/models/campus.model';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.page.html',
  styleUrls: ['./create-account.page.scss'],
  standalone: true,
  imports: [
    IonProgressBar,
    IonText,
    IonLabel,
    IonInput,
    IonItem,
    IonButton,
    IonCardTitle,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCard,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    IonSelect,
    IonSelectOption,
    MaskitoDirective,
    IonInputPasswordToggle,
    VerificationPage,
  ],
})
export class CreateAccountPage {
  loading: boolean = false;
  states = states;
  campuses: Campus[] = [];
  campusValue: Campus | undefined;
  otherCampus: Campus = {
    collegeId: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    isMainCampus: false,
    status: '',
    college: {
      name: '',
      emailDomain: '',
      status: '',
      id: '',
    },
    name: 'Other',
    id: '814e4ed5-ed2a-4c08-914a-d8613ef807cd',
  };

  userInfoForm = new FormGroup(
    {
      firstName: new FormControl('', [
        Validators.required,
        WhiteSpaceCheckValidator,
      ]),
      lastName: new FormControl('', [
        Validators.required,
        WhiteSpaceCheckValidator,
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        WhiteSpaceCheckValidator,
        EduEmailValidator,
      ]),
      status: new FormControl('REGISTERED'),
      newPassword: new FormControl('', [
        Validators.required,
        WhiteSpaceCheckValidator,
      ]),
      confirmNewPassword: new FormControl('', [
        Validators.required,
        WhiteSpaceCheckValidator,
      ]),
    },
    { validators: [PasswordMatchValidator] },
  );

  userProfileForm = new FormGroup({
    phone: new FormControl('', [Validators.required, WhiteSpaceCheckValidator]),
    major: new FormControl('', [Validators.required, WhiteSpaceCheckValidator]),
    campusId: new FormControl('', [Validators.required]),
  });

  registerUserForm = new FormGroup({
    userInfo: this.userInfoForm,
    userProfileInfo: this.userProfileForm,
  });

  readonly phoneMask: MaskitoOptions = {
    mask: [
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  };

  readonly maskPredicate: MaskitoElementPredicate = async (el: HTMLElement) =>
    (el as HTMLIonInputElement).getInputElement();

  constructor(
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
    private userVerificationService: UserVerificationService,
    private authService: AuthService,
    private tokenService: TokenService,
    private campusService: CampusService,
    private alertController: AlertController,
  ) {}

  ionViewWillEnter(): void {
    this.campuses.push(this.otherCampus);
    this.campusValue = this.otherCampus;
  }

  submitform() {
    this.loading = true;
    const registerUserInfo: RegisterUser = this.registerUserForm
      .value as RegisterUser;

    registerUserInfo.userInfo.password =
      this.userInfoForm.controls.newPassword.value!;

    registerUserInfo.role = RoleEnum.Student;

    this.userService.create(registerUserInfo).subscribe({
      next: () => {
        this.loading = false;

        this.authService
          .login(
            registerUserInfo.userInfo.email,
            registerUserInfo.userInfo.password,
          )
          .subscribe({
            next: (response) => {
              this.tokenService.updateTokens(response);
              this.loading = false;
              this.userVerificationService.userEmail =
                this.userInfoForm.controls.email.value!;
              this.router.navigateByUrl('/verification');
            },
            error: (response) => {
              this.loading = false;
              this.toastService.presentToast(
                response.error.suggestedMessage,
                3000,
              );
            },
          });
      },
      error: (response) => {
        this.loading = false;
        this.toastService.presentToast(response.error.suggestedMessage, 4000);
      },
    });
  }

  resetForms() {
    this.userInfoForm.reset();
    this.userProfileForm.reset();
  }

  findCampus(event: any) {
    var queryParams = new Map<string, any>();

    if (
      event.detail.value &&
      event.detail.value?.trim() != '' &&
      event.detail.value?.includes('@')
    ) {
      var campusDomain = event.detail.value;
      queryParams.set('Domain', encodeURIComponent(campusDomain));

      this.campusService.getCampuses(queryParams).subscribe({
        next: (response) => {
          if (response.length != 0) {
            this.campuses = response;
            this.campuses.push(this.otherCampus);
          } else {
            if (campusDomain.includes('@') && campusDomain.includes('.edu')) {
              this.NoCampusFoundAlert();
              this.campusValue = this.otherCampus;
            }
          }
        },
      });
    }
  }

  getCampusValue() {
    return this.campuses.find((c) => c.isMainCampus);
  }

  async NoCampusFoundAlert() {
    const alert = await this.alertController.create({
      header: 'No Campus Found.',
      subHeader:
        'This app is still in beta. Some campus options may not be available at this time!',
      buttons: ['Cancel'],
      cssClass: 'themed-alert',
    });

    await alert.present();
  }
}
