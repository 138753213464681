import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampusDeals } from 'src/app/models/campus.model';
import { Deal } from 'src/app/models/deal.model';
import { DealService } from 'src/app/services/deal.service';
import { 
  IonText,
  IonProgressBar,
  IonList,
  IonButtons,
  IonFooter,
  IonInput,
  IonLabel,
  IonItem,
  IonIcon,
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar
} from "@ionic/angular/standalone";
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import Swiper from 'swiper';
import { DealImage } from 'src/app/models/dealImage.model';
import { AppService } from 'src/app/services/app.service';
import { Subscription } from 'rxjs';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-local-deal-detail',
  templateUrl: './local-deal-detail.page.html',
  styleUrls: ['./local-deal-detail.page.scss'],
  standalone: true,
  imports: [
    IonText,
    IonProgressBar,
    IonList,
    IonButtons,
    IonFooter,
    IonInput,
    IonLabel,
    IonItem,
    IonIcon,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LocalDealDetailsPage {
  campusDeal?: CampusDeals;
  

  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;

  swiperDoc = document.getElementById('#thumbsSwiper');
  swiper?: Swiper;

  backSubscription?: Subscription

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected dealService: DealService,
    protected appService: AppService
  ) {
    register();
  }

  ionViewWillEnter() {
    this.route.paramMap.subscribe((data) => {
      const dealId = data.get('id');

      if (dealId) {
        this.getDealDetails(dealId);
      }
    });

    this.backSubscription = this.appService.backSubject.subscribe((_) => {
      this.router.navigateByUrl('/local-deals');
    });
  }

  ionViewDidLeave() {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
      this.backSubscription = undefined;
    }
  }

  getDealDetails(dealId: string) {
    this.dealService.getCampusDeal(dealId).subscribe(deal => {
      this.campusDeal = deal
    })
  }

  onThumbClick(picture: DealImage) {
    const index = this.campusDeal?.deal?.image?.indexOf(picture);
    this.swiperRef?.nativeElement.swiper.slideTo(index);
  }

  visitDeal(): void {
    if (this.campusDeal?.deal.url && this.campusDeal?.deal.url != '') {
      window.open(this.campusDeal?.deal.url, '_system')
    }
  }
}
