import { Routes } from '@angular/router';
import { HomePage } from './pages/home/home.page';
import { WishListPage } from './pages/wish-list/wish-list.page';
import { JobsInternshipsPage } from './pages/jobs-internships/jobs-internships.page';
import { ApartmentsHousingPage } from './pages/apartments-housing/apartments-housing.page';
import { ShopProductsPage } from './pages/shop-products/shop-products.page';
import { LocalDealsPage } from './pages/local-deals/local-deals.page';
import { MyProfilePage } from './pages/my-profile/my-profile.page';
import { LoginPage } from './pages/login/login.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { EditProfilePage } from './pages/edit-profile/edit-profile.page';
import { UpdatePasswordPage } from './pages/update-password/update-password.page';
import { ProductDetailsPage } from './pages/shop-products/product-details/product-details.page';
import { ChatPage } from './pages/chat/chat.page';
import { MyChatsPage } from './pages/my-chats/my-chats.page';
import { DailyChatPage } from './pages/daily-chat/daily-chat.page';
import { CreateAccountPage } from './pages/create-account/create-account.page';
import { VerificationPage } from './pages/verification/verification.page';
import { LocalDealDetailsPage } from './pages/local-deal-detail/local-deal-detail.page';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPage,
  },
  {
    path: 'home',
    component: HomePage,
    data: {
      pageTitle: 'Main Menu',
    },
  },
  {
    path: 'wish-list',
    component: WishListPage,
    data: {
      pageTitle: 'Wish List',
    },
  },
  {
    path: 'jobs-internships',
    component: JobsInternshipsPage,
    data: {
      pageTitle: 'Jobs & Internships',
    },
  },
  {
    path: 'apartments-housing',
    component: ApartmentsHousingPage,
    data: {
      pageTitle: 'Apartments & Housing',
    },
  },
  {
    path: 'shop-products',
    component: ShopProductsPage,
    data: {
      pageTitle: 'Marketplace',
    },
  },
  {
    path: 'local-deals',
    component: LocalDealsPage,
    data: {
      pageTitle: 'Local Deals',
    },
  },
  {
    path: 'local-deals/:id',
    component: LocalDealDetailsPage,
    data: {
      pageTitle: '',
    }
  },
  {
    path: 'my-profile',
    component: MyProfilePage,
    data: {
      pageTitle: 'My Profile',
    },
  },
  {
    path: 'edit-profile',
    component: EditProfilePage,
    data: {
      pageTitle: 'Edit Profile',
    },
  },
  {
    path: 'update-password',
    component: UpdatePasswordPage,
    data: {
      pageTitle: 'Update Password',
    },
  },
  {
    path: 'my-chats',
    component: MyChatsPage,
    data: {
      pageTitle: 'Chat',
    },
  },
  {
    path: 'chat/:id',
    component: ChatPage,
  },
  {
    path: 'product-details/:id',
    component: ProductDetailsPage,
    data: { pageTitle: 'Product Details' },
  },
  {
    path: 'daily-chat',
    component: DailyChatPage,
    data: {
      pageTitle: 'BiziBuzz',
    },
  },
  {
    path: 'create-account',
    component: CreateAccountPage,
    data: {
      pageTitle: 'Create Account',
    },
  },
  {
    path: 'verification',
    component: VerificationPage,
    data: {
      pageTitle: 'Verification',
    },
  },
];
