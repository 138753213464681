import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loader?: HTMLIonLoadingElement;

  constructor(private loadingController: LoadingController) {}

  async showLoading(message: string) {
    this.hideLoading();

    this.loader = await this.loadingController.create({
      message: message,
      spinner: 'bubbles',
    });

    this.loader.present();
  }

  hideLoading() {
    if (this.loader) {
      this.loader.dismiss();
    }
  }
}
