<ion-progress-bar
  type="indeterminate"
  *ngIf="campusDeal === null"
></ion-progress-bar>

@if (campusDeal) {
<div class="overflow-scroll h-full">
  @if (campusDeal.deal.dealType?.toUpperCase() === 'DEAL') { @if
  (campusDeal.deal.image && campusDeal.deal.image.length !== 0) {
  <swiper-container zoom="true" #swiper [thumbs]="{swiper: swiperDoc}">
    @for (image of campusDeal.deal.image; track image.id) {
    <swiper-slide class="swiper-slide">
      <div class="swiper-zoom-container">
        <img [src]="image.url" class="main-image" />
      </div>
    </swiper-slide>
    }
  </swiper-container>
  } @else {
  <img
    alt="Silhouette of mountains"
    src="https://ionicframework.com/docs/img/demos/card-media.png"
  />
  } }

  <ion-list lines="none">
    <ion-item>
      <ion-label>
        <h1 class="flex justify-center">
          @if (campusDeal.deal.dealType?.toUpperCase() === 'HOUSING') { @if
          (campusDeal.deal.paymentType?.toLowerCase() === 'monthly') {
          {{campusDeal.deal.bedrooms}} Bedroom {{campusDeal.deal.housingType |
          titlecase}} for Rent } @else { {{campusDeal.deal.bedrooms}} Bedroom
          {{campusDeal.deal.housingType | titlecase}}
          {{campusDeal.deal.paymentType | titlecase}} } } @else {
          {{campusDeal.deal.title}} }
        </h1>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>
        @if (campusDeal.deal.dealType?.toUpperCase() === 'JOBOPPORTUNITY') {
        <p class="flex justify-center">
          Listed By: {{campusDeal.deal.businessName}}
        </p>

        <p class="flex justify-center">
          Pay Details: ${{campusDeal.deal.amount}} {{campusDeal.deal.payType |
          titlecase}}
        </p>

        <p class="flex justify-center">
          Employment Type: {{campusDeal.deal.employmentType | titlecase}}
        </p>

        <p class="flex justify-center">
          Address: {{campusDeal.deal.street}} {{campusDeal.deal.city}},
          {{campusDeal.deal.state}}, {{campusDeal.deal.zipCode}}
        </p>

        @if (campusDeal.deal.description && campusDeal.deal.description !== '')
        {
        <p class="description">Description: {{campusDeal.deal.description}}</p>
        } @if (campusDeal.deal.expireDate && campusDeal.deal.expireDate !== '')
        {
        <p class="flex justify-center">
          Expire Date: {{campusDeal.deal.expireDate | date : "M/d/yyyy, h:mm
          a"}}
        </p>
        } } @else if (campusDeal.deal.dealType?.toUpperCase() === 'HOUSING') {
        <p class="flex justify-center">
          Listing Agent: {{campusDeal.deal.listingAgent}}
        </p>

        <p class="flex justify-center">
          # of Bedrooms: {{campusDeal.deal.bedrooms}}
        </p>

        <p class="flex justify-center">
          Address: {{campusDeal.deal.street}} {{campusDeal.deal.city}},
          {{campusDeal.deal.state}}, {{campusDeal.deal.zipCode}}
        </p>

        @if (campusDeal.deal.description && campusDeal.deal.description !== '')
        {
        <p class="description">Description: {{campusDeal.deal.description}}</p>
        } @if (campusDeal.deal.expireDate && campusDeal.deal.expireDate !== '')
        {
        <p class="flex justify-center">
          Expire Date: {{campusDeal.deal.expireDate | date : "M/d/yyyy, h:mm
          a"}}
        </p>
        } } @else {
        <p class="description">{{campusDeal.deal.description}}</p>

        @if (campusDeal.deal.expireDate && campusDeal.deal.expireDate !== '') {
        <p class="flex justify-center">
          Expire Date: {{campusDeal.deal.expireDate | date : "M/d/yyyy, h:mm
          a"}}
        </p>
        } }
      </ion-label>
    </ion-item>

    @if (campusDeal.deal.url && campusDeal.deal.url !== '') {
    <ion-item>
      <ion-label>
        <div class="flex justify-center">
          <ion-button (click)="visitDeal()"> Visit Site </ion-button>
        </div>
      </ion-label>
    </ion-item>
    }
  </ion-list>
</div>
}
