<ion-content [fullscreen]="true">
  <ion-grid>
    <ion-row size="8" class="search-funnel-filter">
      <ion-col
        ><ion-searchbar (ionInput)="filterDeals($event)"></ion-searchbar
      ></ion-col>

      <ion-col size="2">
        <ion-button fill="clear" (click)="dealSort()">
          <ion-icon name="filter"></ion-icon>
        </ion-button>
      </ion-col>

      <ion-col size="2">
        <ion-button fill="clear" (click)="openFilterAlert()">
          <ion-icon name="funnel"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div *ngIf="deals.length === 0" lines="none" class="empty-deals-div">
    <ion-icon name="pricetag-outline" class="no-deals-icon"></ion-icon>
    <ion-label class="no-deals-label">
      <h2>There are currently no local deals.</h2>
    </ion-label>
  </div>

  <ion-modal [isOpen]="image() !== undefined" (didDismiss)="closeModal()">
    <ng-template>
      <ion-content>
        <div (click)="closeModal()">
          <img [src]="this.image()" />
        </div>
      </ion-content>
    </ng-template>
  </ion-modal>

  <ion-card
    *ngFor="let item of deals"
    class="flex justify-between items-center"
  >
    @if(item.deal.dealType?.toUpperCase() === 'DEAL') {
    <div class="flex flex-col w-3/4" (click)="goToDetails(item.id)">
      <ion-card-header class="w-full">
        <ion-card-title> {{item.deal.businessName}} </ion-card-title>
        <ion-card-subtitle> {{item.deal.title}} </ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        @if (item.deal.expireDate && item.deal.expireDate !== '') {
        <div>
          Expire Date: {{item.deal.expireDate | date : "M/d/yyyy, h:mm a"}}
        </div>
        } @if (item.deal.url && item.deal.url !== '') {
        <div>
          <ion-button (click)="visitSite(item.deal.url)">
            Visit Site
          </ion-button>
        </div>
        }
      </ion-card-content>
    </div>

    <div class="open-img" (click)="openModal(item)">
      <img class="max-h-14 mr-3" [src]="item.deal.image?.[0]?.url" />
    </div>

    } @if(item.deal.dealType?.toUpperCase() === 'JOBOPPORTUNITY') {
    <div class="flex flex-col" (click)="goToDetails(item.id)">
      <ion-card-header class="w-full">
        <ion-card-title> {{item.deal.businessName}} </ion-card-title>
        <ion-card-subtitle> {{item.deal.title}} </ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <div class="flex flex-row">
          <div class="mr-1">{{item.deal.payType | titlecase}}</div>
          -
          <div class="ml-1">{{item.deal.employmentType | titlecase}}</div>
        </div>
        <div>{{item.deal.description}}</div>
        <div>
          {{item.deal.street}} {{item.deal.city}}, {{item.deal.state}},
          {{item.deal.zipCode}}
        </div>
      </ion-card-content>
    </div>
    } @if(item.deal.dealType?.toUpperCase() === 'HOUSING') {
    <div class="flex flex-col" (click)="goToDetails(item.id)">
      <ion-card-header class="w-full">
        <ion-card-title>
          <div class="flex flex-row">
            <div class="mr-1">
              {{item.deal.businessName}} - {{item.deal.housingType}}
            </div>
            -
            <div class="mx-1">{{item.deal.amount}}</div>
            <div class="ml-1">{{item.deal.paymentType}}</div>
          </div>
        </ion-card-title>

        <ion-card-subtitle>
          <div class="flex flex-row">
            <div>Listing Agent: {{item.deal.listingAgent | titlecase}}</div>
          </div>
        </ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <div>{{ item.deal.bedrooms }} - bedrooms</div>
        <div>{{item.deal.description}}</div>
        <div>
          {{item.deal.street}} {{item.deal.city}}, {{item.deal.state}},
          {{item.deal.zipCode}}
        </div>
        @if (item.deal.url && item.deal.url !== '') {
        <div>
          <ion-button (click)="visitSite(item.deal.url)">
            Visit Site
          </ion-button>
        </div>
        }
      </ion-card-content>
    </div>
    }
  </ion-card>
</ion-content>
