import {
  Component,
  computed,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
  IonModal,
  IonList,
  IonItem,
  IonButtons,
  ModalController,
  AlertInput,
  AlertController,
  IonBackdrop,
} from '@ionic/angular/standalone';
import { pricetagOutline, listOutline, filter, funnel } from 'ionicons/icons';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DealService } from 'src/app/services/deal.service';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
} from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { MeService } from 'src/app/services/me.service';
import { CampusDeals } from 'src/app/models/campus.model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-local-deals',
  templateUrl: './local-deals.page.html',
  styleUrls: ['./local-deals.page.scss'],
  standalone: true,
  imports: [
    IonButtons,
    IonItem,
    IonList,
    IonModal,
    IonIcon,
    IonButton,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    IonItem,
    IonList,
    IonButtons,
    IonBackdrop,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LocalDealsPage {
  currentSort: WritableSignal<string> = signal('CreatedDate%20desc');
  currentFilter: WritableSignal<string> = signal('All');
  queryParams = new Map<string, any>();
  deals: CampusDeals[] = [];
  categories: any[] = [];
  filteredDeals = [...this.deals];

  image: WritableSignal<string | undefined> = signal(undefined);

  backSubscription?: Subscription;
  user!: UserProfile;

  filterOptions: Signal<AlertInput[]> = computed(() => [
    {
      label: 'All',
      value: 'All',
      type: 'radio',
      checked: this.currentFilter() == 'All',
    },
    {
      label: 'Apartments And Housing',
      value: 'Housing',
      type: 'radio',
      checked: this.currentFilter() == 'Housing',
    },
    {
      label: 'Job Opportunities',
      value: 'JobOpportunity',
      type: 'radio',
      checked: this.currentFilter() == 'JobOpportunity',
    },
    {
      label: 'Deals',
      value: 'Deal',
      type: 'radio',
      checked: this.currentFilter() == 'Deal',
    },
  ]);

  sortOptions: Signal<AlertInput[]> = computed(() => [
    {
      label: 'Newest First',
      type: 'radio',
      value: 'CreatedDate%20desc',
      checked: this.currentSort() == 'CreatedDate%20desc',
    },
    {
      label: 'Oldest First',
      type: 'radio',
      value: 'CreatedDate%20asc',
      checked: this.currentSort() == 'CreatedDate%20asc',
    },
    {
      label: 'Alphabetical (A to Z)',
      type: 'radio',
      value: 'Deal.Title%20asc',
      checked: this.currentSort() == 'Deal.Title%20asc',
    },
    {
      label: 'Alphabetical (Z to A)',
      type: 'radio',
      value: 'Deal.Title%20desc',
      checked: this.currentSort() == 'Deal.Title%20desc',
    },
  ]);

  private searchSubject$ = new Subject<string>();

  public sortButtons = [
    {
      text: 'Apply',
      role: 'confirm',
      handler: (data: string) => {
        this.currentSort.set(data);
        this.queryParams.set('OrderBy', data);

        this.getDeals();
      },
    },
  ];

  public filterButtons = [
    {
      text: 'Apply',
      role: 'confirm',
      handler: (data: string) => {
        this.currentFilter.set(data);
        this.queryParams.set('DealType', data);

        this.getDeals();
      },
    },
  ];

  constructor(
    private dealService: DealService,
    private modalController: ModalController,
    private router: Router,
    private appService: AppService,
    private meService: MeService,
    private alertController: AlertController,
    private loadingService: LoadingService,
  ) {
    addIcons({ filter, funnel, listOutline, pricetagOutline });
  }

  ionViewWillEnter(): void {
    this.queryParams.set('Status', 'ACTIVE');
    this.backSubscription = this.appService.backSubject.subscribe((_) => {
      this.router.navigateByUrl('/home');
    });

    this.meService.getUserProfile().subscribe({
      next: (response) => {
        this.user = response;
        this.queryParams.set('CampusId', response.campusId);
      },
      error: () => {},
    });
    this.queryParams.set('OrderBy', 'CreatedDate%20desc');
    this.getDeals();

    this.searchSubject$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((text) => {
        if (text && text.trim().length > 0) {
          this.queryParams.set('Title', text);
        }

        if (text.trim().length == 0) {
          this.queryParams.delete('Title');
        }
        this.getDeals();
      });
  }

  getDeals() {
    this.loadingService.showLoading('Getting the latest deals!');

    this.meService.getUserProfile().subscribe({
      next: (response) => {
        this.queryParams.set('CampusId', response.campusId);
        this.dealService
          .getCampusDeals(this.queryParams)
          .subscribe((response) => {
            this.deals = response;
            this.loadingService.hideLoading();
          });
      },

      error: () => {
        this.loadingService.hideLoading();
      },
    });
  }

  ionViewWillLeave(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }

    if (this.searchSubject$) {
      this.searchSubject$.complete();
    }
  }

  goToDetails(id: string | undefined) {
    if (id) {
      this.router.navigateByUrl(`/local-deals/${id}`);
    }
  }

  sendToLink(url: string) {
    if (url != '' && url != null) {
      window.open(url);
    }
  }

  openModal(item?: CampusDeals) {
    this.image.set(item?.deal.image?.[0]?.url);
  }

  closeModal() {
    this.image.set(undefined);
  }

  filterDeals(event: CustomEvent) {
    const textValue = event.detail.value;
    this.searchSubject$.next(textValue);
  }

  async dealSort() {
    const alert = await this.alertController.create({
      header: 'Product Sort',
      subHeader: 'Select Sort Criteria Below',
      buttons: this.sortButtons,
      inputs: this.sortOptions(),
      cssClass: 'themed-alert',
    });

    await alert.present();
  }

  async openFilterAlert() {
    const alert = await this.alertController.create({
      header: 'Filter by Category',
      buttons: this.filterButtons,
      inputs: this.filterOptions(),
      cssClass: 'themed-alert',
    });

    await alert.present();
  }

  visitSite(url: string) {
    if (url && url != '') {
      window.open(url, '_system');
    }
  }
}
