import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CampusDeals } from '../models/campus.model';
import { Deal } from '../models/deal.model';

@Injectable({
  providedIn: 'root',
})
export class DealService {
  path = '/api/Deal';
  constructor(private http: HttpClient) {}

  getDeals(queryParams?: Map<string, any>): Observable<any> {
    const queryParametersString = this.buildQueryParameters(queryParams);
    return this.http.get(`${environment.API}${this.path}${queryParametersString}`);
  }

  getCampusDeal(id: string): Observable<CampusDeals> {
    return this.http.get<CampusDeals>(`${environment.API}/api/CampusDeals/${id}`);
  }

  getCampusDeals(queryParams?: Map<string, any>): Observable<CampusDeals[]> {
    const queryParametersString = this.buildQueryParameters(queryParams);
    return this.http.get<CampusDeals[]>(`${environment.API}/api/CampusDeals${queryParametersString}`)
  }

  protected buildQueryParameters(queryParameters?: Map<string, any>): string {
    if (queryParameters == null || queryParameters.size == 0) {
      return '';
    }

    let queryString = '?';

    for (let entry of queryParameters.entries()) {
      if (typeof entry[1] == 'object') {
        for (let val of Array.from(entry[1])) {
          queryString += entry[0] + '=' + val + '&';
        }
      } else {
        queryString += entry[0] + '=' + queryParameters.get(entry[0]) + '&';
      }
    }

    queryString = queryString.substring(0, queryString.length - 1);

    return queryString;
  }
}
